%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: SpikeWave
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 73c526bbb006fde49a6978e33b9c6786,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 7994f284bb25ca64fa21bb890b0e5e98, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 90
  castDelay: 750
  animationSpeed: 1.2
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Righteous Shock
  description: Deal <b>12</b> damage to all enemies within <b>7</b> yards. In addition,
    deal <b>6</b> damage every <b>3</b> seconds to anyone who remains in the effect,
    which lasts <b>9</b> seconds.
  icon: {fileID: 2800000, guid: 881514c4d98e2e34186a2454bee68f48, type: 3}
  usableBy: 
  cooldown: 12
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100056
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 135b61980c304d543981b0d3245307ab, type: 3}
