%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: RighteousHammer
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 73c526bbb006fde49a6978e33b9c6786,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 7994f284bb25ca64fa21bb890b0e5e98, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 39
  castDelay: 400
  animationSpeed: 1.5
  animationDuration: 1.2
  skipCooldownOnChannel: 0
  spellName: Righteous Hammer
  description: "Deal <b>8</b> physical damage shortly followed by <b>8</b> magical
    damage to the target. \n\nIf this deals damage, your next <B>Mystical Brilliance</b>
    within <b>6</b> seconds will be an instant cast."
  icon: {fileID: 2800000, guid: 3bfcddde9508ae34d90af7caea7ec88e, type: 3}
  usableBy: 0d000000
  cooldown: 2
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100032
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 3f56b5f4d38c27347bde75788b333519, type: 3}
