%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: RighteousHindrance
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 73c526bbb006fde49a6978e33b9c6786,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 7994f284bb25ca64fa21bb890b0e5e98, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 128
  castDelay: 750
  animationSpeed: 1
  animationDuration: 1.4
  skipCooldownOnChannel: 0
  spellName: Righteous Hindrance
  description: Deal <b>12</b> damage to the target and prevent them from moving faster
    than their base movement speed for <b>10</b> seconds.
  icon: {fileID: 2800000, guid: 6b113059e73fc494eaaa0727e6b5ea9d, type: 3}
  usableBy: 0d000000
  cooldown: 10
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100060
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 53f5e78702578ec4cbd1f711584a2364, type: 3}
