%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: Slam
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 113
  castDelay: 50
  animationSpeed: 1
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Slam
  description: Slam down on your target, dealing <b>25</b> damage and stunning them
    for <b>4</b> seconds. Must be cast after double jumping. Awards <b>1</b> combo
    point.
  icon: {fileID: 2800000, guid: 852a8a2a1bdbf0a42a6827a6227021a6, type: 3}
  cooldown: 25
  castTime: 0
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100016
  usableBy: 0a000000
  luaScript: {fileID: 4900000, guid: 67220f1141174204487eb86fbc257b18, type: 3}
