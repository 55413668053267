%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: TemplateSpellData
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  castAuraPrefab: {fileID: 0}
  castSound: {fileID: 0}
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  castDelay: 0
  animationSpeed: 1
  animationDuration: 1
  enableWeaponGlow: 0
  skipCooldownOnChannel: 0
