%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerTutorialWarrior
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2853453332119588009, guid: f552254a024425747a17e5058dce1260,
    type: 3}
  heroClass: 16
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 208
  unitName: Tarcza
  unitSpec: Fighter
  icon: {fileID: 2800000, guid: 020cc09f56b4c9341b4f380652c83f4b, type: 3}
  backgroundColor: {r: 0.6588235, g: 0.4705882, b: 0.2431373, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 95cbed828bf835049b3345c4a977845e, type: 3}
  type: BRUISER
  description: Tarcza, is a seasoned Fighter renowned for his mastery with both sword
    and shield. His combat style is characterized by relentless offense combined
    with strategic defense. Specializing in inflicting consistent, high damage to
    adversaries, Tarcza excels at nullifying his opponents' abilities to cast spells
    or maneuver effectively. However, his own weakness lies in susceptibility to
    control tactics employed by his foes. Despite this vulnerability, Tarcza's unwavering
    determination and precise combat techniques make him a force to be reckoned with
    in the arena, where he dominates with his offensive and tactical prowess.
  passiveIcon: {fileID: 21300000, guid: cbe9b237356d9e848800b25063f1c444, type: 3}
  passiveName: Shattered Armor
  passiveDescription: Every time you cast a damaging spell on your opponent, add
    a stack of <b>Shattered Armor</b>, increasing the physical damage they take by
    <b>1%</b> per stack. Stacks up to <b>5</b> times.
