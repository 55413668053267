%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: SoulSiphon
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 7f1c197a232a3d345aaa061a8840dedd,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 2836c0e6012a3e94e90f1174c296b6fc, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 432
  castDelay: 400
  animationSpeed: 1.1
  animationDuration: 1.2
  skipCooldownOnChannel: 0
  spellName: Soul Siphon
  description: Deal <b>6</b> damage to your target every <b>3</b> seconds for <b>18</b>
    seconds.
  icon: {fileID: 2800000, guid: 21ced9b8d65d7fb42ab0a74cabd34d1c, type: 3}
  usableBy: 08000000
  cooldown: 18
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100049
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 82f91f3da9c5c994d9eb7fca1f107d89, type: 3}
