%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: IcyExplosion
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 31f0c923b7b660e47938376fb189e949,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 14
  castDelay: 180
  animationSpeed: 2.5
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Icy Explosion
  description: Deal <b>3</b> damage to all targets in an <b>10</b> yard radius from
    the caster and prevent them from moving for <b>8</b> seconds. Targets are considered
    <b>Frozen</b> for this duration.
  icon: {fileID: 2800000, guid: f12f21bd276edf24eaa127697c232784, type: 3}
  usableBy: 00000000
  cooldown: 18
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
