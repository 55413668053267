%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: ResidualImplosion
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: c6cb37df2c0db6e47b38d20f306e416a,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: c6a46772a396efa4c95545ff89c47323, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 356
  castDelay: 450
  animationSpeed: 1.5
  animationDuration: 0.8
  skipCooldownOnChannel: 0
  spellName: Residual Implosion
  description: Deal <b>24</b> damage to the target. The target must have <b>Pestulant
    Devastation</b> applied.
  icon: {fileID: 2800000, guid: 65b585752fad6ff46849bb3991aaa32f, type: 3}
  usableBy: 0c000000
  cooldown: 10
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100028
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: a65387cf0412c91488a2958fd2de08e0, type: 3}
