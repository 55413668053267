%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: RighteousPrivilege
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 73c526bbb006fde49a6978e33b9c6786,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 20
  castDelay: 420
  animationSpeed: 2
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Righteous Privilege
  description: Instantly remove all <b>Slows</b> from the target. In addition, the
    target cannot be <b>Slowed</b> for <b>10</b> seconds.
  icon: {fileID: 2800000, guid: cb0069029c9a66849bd251f1456ed963, type: 3}
  usableBy: 070000000d000000
  cooldown: 30
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 027489edcf0967b4c9cd9e38e44be6cf, type: 2}
