%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerDarkPriest
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2777422822122510713, guid: 4e97436c6d3330246a037b6dc6dba6c7,
    type: 3}
  heroClass: 8
  isHealer: 1
  level: 0
  attackSpeed: 1
  baseHP: 148
  unitName: Kanna
  unitSpec: Soulstealer
  icon: {fileID: 2800000, guid: 3d37dcc66de5bc148b9e40e6300cfa88, type: 3}
  backgroundColor: {r: 0.5283019, g: 0.28608045, b: 0.50953025, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: HEALER
  description: Kanna, the enigmatic Soulstealer of the Ring of Titans, maneuvers
    the battlefield with a delicate balance of sacrifice and salvation. Renowned
    for her unparalleled support, she deftly manipulates the essence of souls, weaving
    a web of interconnected life force among her allies. In the throes of combat,
    Kanna selflessly redistributes vitality, drawing from her own spirit and those
    of her companions to sustain the team's resilience. With each exchange, she orchestrates
    a symphony of healing, ensuring her comrades endure the trials of the arena with
    unwavering fortitude.
  passiveIcon: {fileID: 21300000, guid: 952aff501826fd84594c9b2b2e334e1b, type: 3}
  passiveName: Tough Soul
  passiveDescription: Take up to <b>20%</b> reduced damage as your current health
    decreases.
