%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerCorruptorDruid
  m_EditorClassIdentifier: 
  unitModel: {fileID: 3241138547371686306, guid: d7a299f5825fe1c4482c6261124bb072,
    type: 3}
  heroClass: 14
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 174
  unitName: Airion
  unitSpec: Stormcaller
  icon: {fileID: 2800000, guid: 18901b897df0f664dbdc34927f5c0228, type: 3}
  backgroundColor: {r: 0.90588236, g: 0.4630687, b: 0.121568635, a: 1}
  textColor: {r: 0.4, g: 0.4, b: 0.4, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: HYBRID
  description: "In the Ring of Titans, Airion is a formidable Stormcaller. With crackling
    lightning at his command, Airion unleashes devastating storms upon adversaries,
    striking fear into the hearts of challengers. However, beyond his mastery of
    tempestuous forces, Airion possesses another gift \u2013 the ability to harness
    the powers of nature for healing and support. With a deft touch, he can mend
    wounds and bolster allies, balancing the ferocity of the storm with the tranquility
    of the natural world. As a versatile combatant, Airion stands as both a fearsome
    opponent and a steadfast ally, his prowess echoing like thunder across the battlefield."
  passiveIcon: {fileID: 21300000, guid: e7dae896993f7c144bdd4b51b3fddf24, type: 3}
  passiveName: Lightning Mastery
  passiveDescription: Deal an additional 15% magic damage while in <b>Lightning Mode</b>.
