%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: FreezingTrap
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: e1469613e3c96a34a99dc366ba596c51,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 18
  castDelay: 275
  animationSpeed: 2
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Freezing Trap
  description: Shoot a freezing trap at the targeted area, <b>Incapacitating</b>
    the closest target for <b>10</b> seconds. Any damage dealt to the target will
    break the effect.
  icon: {fileID: 2800000, guid: e3c08afc92535b2449c4d61c202511c7, type: 3}
  usableBy: 06000000
  cooldown: 34
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 1.5
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
