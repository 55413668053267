%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: SpiritSector
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 7f1c197a232a3d345aaa061a8840dedd,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 8300000, guid: 2c783095a3b2c8a4fa8cae902699c577, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 323
  castDelay: 0
  animationSpeed: 0.45
  animationDuration: 1.3
  skipCooldownOnChannel: 0
  spellName: Spirit Sector
  description: Create a <b>Spirit Sector</b>. Any teammates in the <b>Spirit Sector</b>
    will take <b>20%</b> less damage. Lasts <b>10</b> seconds.
  icon: {fileID: 2800000, guid: 4c194d689d04caf4993e635d42b3be3f, type: 3}
  usableBy: 08000000
  cooldown: 24
  castTime: 1
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 8
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
  spellKey: 100048
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: f760ce282a4fedc4283efc7330de2b44, type: 3}
