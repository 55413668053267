%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: CycloneShot
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: e1469613e3c96a34a99dc366ba596c51,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 964fdba38affb6b458daf089802e5431, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 411
  castDelay: 0
  animationSpeed: 0.5
  animationDuration: 4.5
  skipCooldownOnChannel: 0
  spellName: Cyclone Shot
  description: "Shoot arrows in a circle for <b>4</b> seconds, dealing <b>10</b>
    damage every <b>0.5</b> seconds to all enemy Titans within <b>10</b> yards. \n\nYou
    move <b>30%</b> slower for the duration, and cannot cast any other spells."
  icon: {fileID: 2800000, guid: 08c066d7588cf9941b8a91b2262dc1c2, type: 3}
  usableBy: 0f000000
  cooldown: 42
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100074
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 596770eaea22461489398a7e1bf98283, type: 3}
