%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: LongIncap
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: c6cb37df2c0db6e47b38d20f306e416a,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 8300000, guid: 2c783095a3b2c8a4fa8cae902699c577, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 356
  castDelay: 0
  animationSpeed: 1.5
  animationDuration: 0.8
  skipCooldownOnChannel: 0
  spellName: Soul Tether
  description: Place an orb at the targeted location that binds with your target,
    <b>Incapacitating</b> them for <b>10</b> seconds. If anyone steps on the orb,
    it will break the effect. If any damage is dealt to the target, it will break
    the effect.
  icon: {fileID: 2800000, guid: cf94875e2d335e945be76e5cf0370372, type: 3}
  usableBy: 0c000000
  cooldown: 20
  castTime: 0.5
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 1
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 26ea0a465cecd914aa777556b0118bbb, type: 2}
  spellKey: 100031
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: f0323aafe07f0844f957ded7ac5d3634, type: 3}
