%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerHunter
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2910940308793556349, guid: d2c76fa636f5cb84dbaa1aceb77c021b,
    type: 3}
  heroClass: 6
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 184
  unitName: Orin
  unitSpec: Hunter
  icon: {fileID: 2800000, guid: eb8ceac05c3baf242a6b90310f4abd40, type: 3}
  backgroundColor: {r: 0.6666667, g: 0.827451, b: 0.44705883, a: 1}
  textColor: {r: 0.4, g: 0.4, b: 0.4, a: 1}
  typeIcon: {fileID: 21300000, guid: 95cbed828bf835049b3345c4a977845e, type: 3}
  type: RANGED
  description: Orin, a seasoned Hunter in the renowned Ring of Titans, wields his
    trusty bow with unparalleled skill and finesse. Swift and nimble, he dances across
    the arena with remarkable agility, always maintaining distance from his foes.
    His precision and accuracy with the bow are legendary, consistently delivering
    devastating blows that leave his opponents reeling. However, despite his prowess
    from afar, Orin is notably vulnerable when adversaries close in on him, lacking
    the brute strength or close-quarters combat expertise of his counterparts. Nonetheless,
    his mastery of ranged combat ensures that he remains a formidable force to be
    reckoned with in the arena, his arrows striking true and his determination unyielding.
  passiveIcon: {fileID: 21300000, guid: 0663a9fb22239e24ba94016599adc2e3, type: 3}
  passiveName: Disengaged
  passiveDescription: Every successful arrow shot increases your movement speed by
    <b>7%</b>. Stacks up to <b>6</b> times.
