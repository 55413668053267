%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: DarkSuppression
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 7f1c197a232a3d345aaa061a8840dedd,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 2836c0e6012a3e94e90f1174c296b6fc, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 22
  castDelay: 450
  animationSpeed: 3
  animationDuration: 0.55
  skipCooldownOnChannel: 0
  spellName: Dark Suppression
  description: IInstantly remove all <b>Slows</b> and reduce all damage taken by
    <b>90%</b>. The effect lasts <b>5</b> seconds or until canceled. Can be cast
    while <b>Stunned</b>, <b>Incapacitated</b>, or <b>Silenced</b>.
  icon: {fileID: 2800000, guid: dfa8586691105d846b2d434d94b692e5, type: 3}
  usableBy: 08000000
  cooldown: 40
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100007
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 4f2c56c1997de8d4da4dd1258bb7138a, type: 3}
