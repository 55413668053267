import logo from "./img/logo.png?width=600";
import hero from "./img/library_hero.png?width=960";
import { Button } from "./LandingPage";
import { Link } from "react-router-dom";

export function Header(props) {
  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        backgroundImage: "url(" + hero + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        borderRadius: 16,
        position: "relative",
        zIndex: 1,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(238,246,252,0) 30%)",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Link to="/">
          <div style={{ width: 300, height: 140, overflow: "hidden" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: "url(" + logo + ")",
                backgroundSize: "120%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50%",
              }}
            />
          </div>
        </Link>
        <h1
          style={{
            textTransform: "uppercase",
            textShadow: "0 0 10px #444",
            margin: 0,
            color: "white",
          }}
        >
          {props.label}
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Button
            link="https://ring-of-titans.fandom.com"
            icon={<i className="fas fa-book" />}
            style={{
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
            }}
          >
            WIKI
          </Button>
          <Button
            link="/titans"
            icon={<i className="fas fa-bars" />}
            style={{}}
          >
            TITANS
          </Button>
          <Button
            link="/leaderboards"
            icon={<i className="fas fa-crown" />}
            style={{}}
          >
            LEADERBOARDS
          </Button>
          <Button
            link="/patchnotes"
            icon={<i className="fas fa-book" />}
            style={{}}
          >
            PATCH NOTES
          </Button>
          <Button
            link="https://store.steampowered.com/app/1460550/Ring_of_Titans/"
            icon={<i className="fab fa-steam" />}
            target="_blank"
            style={{
              borderBottomRightRadius: 12,
              borderTopRightRadius: 12,
            }}
          >
            PLAY FREE
          </Button>
        </div>
      </div>
    </div>
  );
}
