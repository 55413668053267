%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: RighteousDefenseCancel
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 14
  castDelay: 0
  animationSpeed: 3
  animationDuration: 0
  skipCooldownOnChannel: 0
  spellName: Cancel Righteous Defense
  description: Remove <b>Righteous Defense</b> from yourself.
  icon: {fileID: 2800000, guid: 94dd8715df5de1d4884dc8a001e5c39a, type: 3}
  usableBy: 000000000100000002000000030000000400000005000000060000000700000008000000090000000a0000000b0000000c0000000d0000000e0000000f000000
  cooldown: 1
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 0
  aoeRadius: 0
  isHidden: 1
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: a8cd7cc6f0212db4a8228be1ba9145b6, type: 2}
