%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: Slash2
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.05
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: d44b46a27bd0a8f4e83641e36dffdc4b, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 41
  castDelay: 0
  animationSpeed: 1.5
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Slash
  description: '<b>First Cast</b>: Slash forward, dealing 8 damage to the first target
    hit.


    <b>Second Cast</b>: Slash forward, dealing 12 damage to the first
    target hit.


    <b>Third Cast</b>: Leap forward, dealing 26 damage and stunning
    the first target hit for 3 seconds.'
  icon: {fileID: 2800000, guid: 4a614e9a23441a544a07a7b011cbc913, type: 3}
  usableBy: 0b000000
  cooldown: 1
  castTime: 0.25
  isCastInterruptible: 0
  channelTime: 0
  isOnGlobalCooldown: 0
  aoeRadius: 0
  isHidden: 1
  isInternal: 1
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 1
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100045
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 830b5171d02217b4eba097c7733f4251, type: 3}
