import { Link } from "react-router-dom";
import { PageLayout } from "./PageLayout";
import { MediaContent } from "./PatchNotes";
import purchase from "./img/purchase_options.png";
import no_code from "./img/no_code_dialog.png";

export default function CreatorsProgram() {
  return (
    <PageLayout label="Creators">
      <div
        style={{
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        <h2>CREATOR PROGRAM</h2>
        <p>
          The Creator Program is a program where affiliated creators are able to
          share in a percentage of revenue they bring, identified by players who
          enter a "Creator Code" prior to making a purchase. By providing an
          email for payouts and participating in this program you agree to the
          terms laid out on this page. This program is subject to change in the
          future. Such changes will be effective immediately upon being posted
          on this page. The most current version of the Creator Program
          supersedes all previous versions.
        </p>
        <p>
          <strong>The Creator Program works as follows:</strong>
        </p>
        <ul>
          <li>
            When a player purchases Obols, they are able to enter a Creator
            Code.
          </li>
          <li>
            All creators participating in the program are provided a Creator
            Code (e.g. <strong>MASH</strong>).
          </li>
          <li>
            Players who enter a Creator Code prior to purchase receive 5%
            additional reward from their purchase, to incentivize them to find
            and enter a Creator Code.
          </li>
          <li>
            When a player completes a purchase, the Creator Code that was used
            is recorded.
          </li>
          <li>
            The creator who owns the provided Creator Code will receive{" "}
            <strong>40%</strong> of the payment amount, after the payment has
            been verified.
          </li>
          <ul>
            <li>
              For example, if a player makes a purchase for $30 USD using your
              Creator Code, you will receive $12 USD in the next payout window
              after the payment has been verified.
            </li>
          </ul>
          <li>It can take up to 30 days to verify a payment.</li>
          <li>Payouts occur once per month.</li>
          <li>
            Payouts can only occur if a valid PayPal email has been provided
            using the <Link to="/creator">Creator Portal</Link>.
          </li>
          <li>
            Payouts will only include verified transactions from the prior
            window.
          </li>
        </ul>

        <MediaContent
          media={purchase}
          subtitle="Options for purchasing Obols"
        />
        <MediaContent
          media={no_code}
          subtitle="Players are strongly incentivized to find and enter a creator code"
        />

        <h2>EXAMPLE</h2>
        <ul>
          <li>You have been invited to the Creator Program.</li>
          <li>
            You have not entered your PayPal email on the{" "}
            <Link to="/creator">Creator Portal</Link>.
          </li>
          <li>Your first payout is set to occur on Sept 1st.</li>
          <li>
            On Aug 1st, a player makes a $50 purchase using your creator code.
          </li>
          <li>
            On Aug 20th, the purchase from Aug 1st is verified not to be
            fraudulent.
          </li>
          <li>
            On Sept 1st, you will not receive a payout since no PayPal email has
            been set on the <Link to="/creator">Creator Portal</Link>.
          </li>
          <li>Your next payout is now set to occur on Oct 1st.</li>
          <li>
            On Sept 2nd, a player makes a $40 purchase using your creator code.
          </li>
          <li>
            On Sept 5th, you add a PayPal email on the{" "}
            <Link to="/creator">Creator Portal</Link>.
          </li>
          <li>
            On Sept 25th, the purchase from Sept 2nd is verified not to be
            fraudulent.
          </li>
          <li>
            On Sept 30th, a player makes a $30 purchase using your creator code.
          </li>
          <li>
            On Oct 1st, you will receive $36 (40% of $40 + 40% of $50) to the
            PayPal email you set on the{" "}
            <Link to="/creator">Creator Portal</Link>.
          </li>
          <li>Your next payout is now set to occur on Nov 1st.</li>
          <li>
            On Oct 20th, the payment from Sept 30th is verified not to be
            fraudulent.
          </li>
          <li>
            On Nov 1st, you will receive $12 (40% of $30) to the PayPal email
            you set on the <Link to="/creator">Creator Portal</Link>.
          </li>
        </ul>

        <h3>FAQ</h3>
        <h4>WHEN DOES THIS PROGRAM BEGIN?</h4>
        <p>
          The Creator Program starts on Oct 21st, 2024, when the 1.0 version of
          Ring of Titans releases. Until that time, players will be unable to
          purchase Obols, and therefore you will be unable to receive anything.
        </p>
        <h4>I WAS INVITED, HOW DO I SIGN UP?</h4>
        <p>
          If you've been invited, you already have a Creator Code assigned to
          you. You can simply share that code with players if you choose to do
          so. Whenever players use your code, it will be recorded and tracked.
          To use the <Link to="/creator">Creator Portal</Link>, just sign up
          using the email address where you recieved the invitation. On the{" "}
          <Link to="/creator">Creator Portal</Link>, you will be able to see the
          list of orders using your Creator Code, how much you can expect from
          each, and whether each transaction has been verified yet. You will
          also be able to choose a PayPal email where we can send payouts each
          month.
        </p>
        <h4>HOW DO I RECEIVE PAYOUTS?</h4>
        <p>
          After providing your Paypal email address on the{" "}
          <Link to="/creator">Creator Portal</Link>, there is nothing else to
          do. Payouts will automatically be made to your PayPal account once per
          month and include 40% of all verified transactions from the prior
          window as USD.
        </p>
        <h4>HOW DO I TRACK MY EXPECTED PAYOUTS?</h4>
        <p>
          On the <Link to="/creator">Creator Portal</Link>, you will be able to
          see the list of orders using your Creator Code, how much you can
          expect from each, and whether the transaction has been verified yet.
          You will also get an estimated payout date and amount for your next
          payout.
        </p>
        <h4>WHO IS ELIGIBLE TO PARTICIPATE IN THIS PROGRAM?</h4>
        <p>
          Currently only creators who have been selected are eligible to
          participate. If you feel you would be a good fit for the program,
          please feel free to send an email to support@ringoftitans.com.
        </p>
        <h4>
          WILL MY CREATOR CODE WORK IF I HAVE NOT PROVIDED A PAYPAL EMAIL?
        </h4>
        <p>
          Yes, players will be able to enter your Creator Code if you have been
          provided one, even if you have not yet provided a PayPal email for
          payouts. All orders using your Creator Code will be recorded and you
          will be able to join the program at any time. However, transactions
          over 1 year old at the time you provide your PayPal email are subject
          to forfeiture.
        </p>
        <h4>WHAT IS A PAYMENT VERIFICATION?</h4>
        <p>
          In order to prevent fraudulent purchases, there is a verification
          period for each payment that occurs. You are able to see the current
          status of each payment on the{" "}
          <Link to="/creator">Creator Portal</Link>. Payments can take up to 30
          days to be verified.
        </p>
        <h4>WHEN WILL I RECEIVE MY PAYOUT?</h4>
        <p>
          After providing your PayPal email on the{" "}
          <Link to="/creator">Creator Portal</Link>, expect 1-2 months before
          your first payout can occur. After the first payout, you should expect
          payouts every month at a similar cadence.
        </p>
        <h4>HOW CAN I CHANGE MY CREATOR CODE?</h4>
        <p>
          Please reach out to support@ringoftitans.com to update your Creator
          Code, or for any other questions about the program.
        </p>
        <h4>HOW DO PLAYERS FIND MY CREATOR CODE?</h4>
        <p>
          There is no way for players to find your Creator Code if you do not
          tell them about it. You are free to let your viewers know about your
          Creator Code however you would like. The game will not provide a list
          of options to players, they must manually type or paste in the Creator
          Code themselves when making a purchase.
        </p>
        <h4>HOW DO I DISCLOSE THAT I'M IN THE PROGRAM?</h4>
        <p>
          If you choose to share your Creator Code with players and provide your
          PayPal to receive payouts, please make sure to properly disclose to
          players that you are participating in the Creator Program. You can do
          so by including a hashtag such as #Ad, #Sponsored, explicit text such
          as "I am participating in the Ring of Titans affiliate program and may
          receive payouts from your in-game purchases.", or similar methods. The
          disclosure must be easily accessible to users, without them having to
          specifically search for it.
        </p>
      </div>
    </PageLayout>
  );
}
