%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: Dash
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 88
  castDelay: 0
  animationSpeed: 1
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Dash
  description: Dash forward <b>20</b> yards. Any targets in your path take <b>10</b>
    damage and bleed for <b>5</b> damage a second for <b>9</b> seconds. Gain <b>1</b>
    combo point for each target hit.
  icon: {fileID: 2800000, guid: 98ac4f5f246f2a242a1944b8332fac48, type: 3}
  usableBy: 0a000000
  cooldown: 25
  castTime: 0.25
  isCastInterruptible: 0
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100017
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: e8baebc57f5f1034ea06ad779c0e5f31, type: 3}
