%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: VirulentEruption
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: b1e3c0f02d3d52e4282044628720bede,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: c6a46772a396efa4c95545ff89c47323, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 21
  castDelay: 450
  animationSpeed: 2.2
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Virulent Eruption
  description: Deal <b>13</b> damage and <b>Stun</b> all enemies in a <b>8</b> yard
    radius at the targeted location for <b>3</b> seconds.
  icon: {fileID: 2800000, guid: 242d2ed074ec33543b021df1127530eb, type: 3}
  usableBy: 0c000000
  cooldown: 26
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 8
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
  spellKey: 100030
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 8219d4e10ac3eed43a6c787af851f961, type: 3}
