%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: Kick
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 11
  castDelay: 0
  animationSpeed: 1.5
  animationDuration: 0.5
  skipCooldownOnChannel: 0
  spellName: Kick
  description: If the target is casting, <b>Interrupt</b> them and prevent them from
    casting that spell for <b>4</b> seconds and any other spell for <b>2</b> seconds.
  icon: {fileID: 2800000, guid: 4bac57cf058bba546ab93e97c1ada84f, type: 3}
  usableBy: 010000000a000000
  cooldown: 10
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 0
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 12d2ef2f459217549b413b4b15bf4b0e, type: 2}
