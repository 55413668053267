import { useCallback, useEffect, useState } from "react";
import mage from "./img/mage.png?width=32";
import rogue from "./img/rogue.png?width=32";
import priest from "./img/priest.png?width=32";
import warlock from "./img/warlock.png?width=32";
import warrior from "./img/warrior.png?width=32";
import druid from "./img/druid.png?width=32";
import hunter from "./img/hunter.png?width=32";
import paladin from "./img/paladin.png?width=32";
import dark_priest from "./img/soulstealer.png?width=32";
import crystal_mage from "./img/crystalmage.png?width=32";
import bruiser_rogue from "./img/bruiser.png?width=32";
import blademaster_warrior from "./img/blademaster.png?width=32";
import eradicator_warlock from "./img/eradicator.png?width=32";
import smiter_paladin from "./img/smiter.png?width=32";
import corruption_airion from "./img/stormcaller.png?width=32";
import marksman_orin from "./img/marksman.png?width=32";
import unranked from "./img/ranks/unranked.png?width=32";
import bronze1 from "./img/ranks/Bronze1.png?width=32";
import bronze2 from "./img/ranks/Bronze2.png?width=32";
import bronze3 from "./img/ranks/Bronze3.png?width=32";
import silver1 from "./img/ranks/silver1.png?width=32";
import silver2 from "./img/ranks/silver2.png?width=32";
import silver3 from "./img/ranks/silver3.png?width=32";
import gold1 from "./img/ranks/gold1.png?width=32";
import gold2 from "./img/ranks/gold2.png?width=32";
import gold3 from "./img/ranks/gold3.png?width=32";
import platinum1 from "./img/ranks/platinum1.png?width=32";
import platinum2 from "./img/ranks/platinum2.png?width=32";
import platinum3 from "./img/ranks/platinum3.png?width=32";
import diamond1 from "./img/ranks/diamond1.png?width=32";
import diamond2 from "./img/ranks/diamond2.png?width=32";
import diamond3 from "./img/ranks/diamond3.png?width=32";
import titan1 from "./img/ranks/titan1.png?width=32";
import titan2 from "./img/ranks/titan2.png?width=32";
import titan3 from "./img/ranks/titan3.png?width=32";
import grandtitan from "./img/ranks/grandtitan.png?width=32";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "./PageLayout";
import callFirebaseFunction from "./firebase/callFirebaseFunction";

const CLASSES = [
  "mage",
  "rogue",
  "priest",
  "warlock",
  "warrior",
  "druid",
  "hunter",
  "paladin",
  "dark_priest",
  "crystal_mage",
  "bruiser_rogue",
  "blademaster_warrior",
  "eradicator_warlock",
  "smiter_paladin",
  "corruption_airion",
  "marksman_orin",
];

export const CLASS_ICON_MAP = {
  rogue,
  mage,
  druid,
  priest,
  warrior,
  warlock,
  hunter,
  paladin,
  dark_priest,
  crystal_mage,
  bruiser_rogue,
  blademaster_warrior,
  eradicator_warlock,
  smiter_paladin,
  corruption_airion,
  marksman_orin,
};

export const TYPES = {
  ones: "DUEL",
  twos: "2v2",
  threes: "3v3",
  ctf: "CTF",

  ones_bot: "DUEL vs BOT",
  twos_bot: "2v2 vs BOTS",
  threes_bot: "3v3 vs BOTS",
  ctf_bot: "CTF vs BOTS",
};

export const SEASONS = {
  alpha: "ALPHA",
  s1: "EARLY ACCESS SEASON 1",
  s2: "EARLY ACCESS SEASON 2",
  full_s1: "SEASON 1",
};

// public enum ArenaRank {
//   UNRANKED = 0,
//   BRONZE_I,
//   BRONZE_II,
//   BRONZE_III,
//   SILVER_I,
//   SILVER_II,
//   SILVER_III,
//   GOLD_I,
//   GOLD_II,
//   GOLD_III,
//   PLATINUM_I,
//   PLATINUM_II,
//   PLATINUM_III,
//   DIAMOND_I,
//   DIAMOND_II,
//   DIAMOND_III,
//   TITAN_I,
//   TITAN_II,
//   TITAN_III,
//   GRAND_TITAN,
// }
const RANKS = {
  0: {
    icon: unranked,
    name: "UNRANKED",
    color: "#756158",
  },
  1: {
    icon: bronze1,
    name: "BRONZE I",
    color: "#BA6E31",
  },
  2: {
    icon: bronze2,
    name: "BRONZE II",
    color: "#BA6E31",
  },
  3: {
    icon: bronze3,
    name: "BRONZE III",
    color: "#BA6E31",
  },
  4: {
    icon: silver1,
    name: "SILVER I",
    color: "#D9D7D1",
  },
  5: {
    icon: silver2,
    name: "SILVER II",
    color: "#D9D7D1",
  },
  6: {
    icon: silver3,
    name: "SILVER III",
    color: "#D9D7D1",
  },
  7: {
    icon: gold1,
    name: "GOLD I",
    color: "#FFCA40",
  },
  8: {
    icon: gold2,
    name: "GOLD II",
    color: "#FFCA40",
  },
  9: {
    icon: gold3,
    name: "GOLD III",
    color: "#FFCA40",
  },
  10: {
    icon: platinum1,
    name: "PLATINUM I",
    color: "#3FFFC6",
  },
  11: {
    icon: platinum2,
    name: "PLATINUM II",
    color: "#3FFFC6",
  },
  12: {
    icon: platinum3,
    name: "PLATINUM III",
    color: "#3FFFC6",
  },
  13: {
    icon: diamond1,
    name: "DIAMOND I",
    color: "#3FC1FF",
  },
  14: {
    icon: diamond2,
    name: "DIAMOND II",
    color: "#3FC1FF",
  },
  15: {
    icon: diamond3,
    name: "DIAMOND III",
    color: "#3FC1FF",
  },
  16: {
    icon: titan1,
    name: "TITAN I",
    color: "#3FFF52",
  },
  17: {
    icon: titan2,
    name: "TITAN II",
    color: "#3FFF52",
  },
  18: {
    icon: titan3,
    name: "TITAN III",
    color: "#3FFF52",
  },
  19: {
    icon: grandtitan,
    name: "GRAND TITAN",
    color: "#FF252B",
  },
};

const getIconForClass = (cls) => {
  return CLASS_ICON_MAP[cls];
};

const Row = ({ number, icon, name, classIcon, rank, highlight }) => {
  const highlightStyle = highlight
    ? {
        background: "rgba(255, 255, 255, 0.05)",
        borderRadius: 8,
      }
    : {};

  return (
    <div
      style={{
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        padding: 8,
        ...highlightStyle,
      }}
    >
      <div
        style={{
          display: "inline-block",
          width: 40,
          textAlign: "right",
          marginRight: 16,
        }}
      >
        {number}
      </div>
      <div style={{ display: "inline-block", marginRight: 8 }}>{icon}</div>
      <div
        style={{
          display: "inline-block",
          marginRight: 8,
          width: 340,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </div>
      <div
        style={{ display: "inline-block", marginRight: 8, textAlign: "center" }}
      >
        {classIcon}
      </div>
      <div
        style={{
          display: "inline-block",
          flex: 1,
          textAlign: "right",
          fontSize: 22,
        }}
      >
        {rank}
      </div>
    </div>
  );
};

const useData = (season, type) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);

    const controller = new AbortController();

    callFirebaseFunction("getLeaderboards3", {
      season,
      type,
    }).then((response) => {
      console.log(response);
      if (!controller.signal.aborted) {
        setData(response);
      }
    });

    return () => controller.abort();
  }, [season, type]);

  return data;
};

const LeaderboardWrapper = ({
  children,
  type,
  season,
  onTypeChanged,
  onSeasonChanged,
}) => {
  const typeOptions = Object.keys(TYPES).map((key) => {
    return (
      <option key={key} value={key}>
        {TYPES[key]}
      </option>
    );
  });

  const seasonOptions = Object.keys(SEASONS).map((key) => {
    return (
      <option key={key} value={key}>
        {SEASONS[key]}
      </option>
    );
  });

  return (
    <PageLayout label="Leaderboards">
      <div
        style={{
          width: "100%",
        }}
      >
        <div>
          <select
            value={season}
            onChange={(e) => onSeasonChanged(e.target.value)}
            style={{ marginRight: 8 }}
          >
            {seasonOptions}
          </select>
          <select value={type} onChange={(e) => onTypeChanged(e.target.value)}>
            {typeOptions}
          </select>
        </div>
        <div>{children}</div>
      </div>
    </PageLayout>
  );
};

function darkenHexColor(hexColor, percent) {
  // Ensure the hex color starts with a #
  if (hexColor.startsWith("#")) {
    hexColor = hexColor.slice(1);
  }

  // Convert the hex color to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the new RGB values by reducing brightness
  const darken = (color, percent) =>
    Math.max(0, Math.min(255, Math.floor(color * (1 - percent / 100))));

  const newR = darken(r, percent);
  const newG = darken(g, percent);
  const newB = darken(b, percent);

  // Convert the new RGB values back to hex and return the result
  const toHex = (value) => value.toString(16).padStart(2, "0");

  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
}

export function RankDisplay({ rank }) {
  return (
    RANKS[rank] != null && (
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            color: RANKS[rank].color,
            textShadow: "0 0 5px " + darkenHexColor(RANKS[rank].color, 40),
          }}
        >
          {RANKS[rank] ? RANKS[rank].name : null}
        </div>
        <div style={{ width: 32, height: 32, position: "relative" }}>
          <div
            style={{
              width: 1,
              height: 1,
              position: "absolute",
              top: 16,
              left: 16,
              boxShadow: "0 0 7px 7px " + RANKS[rank].color + "44",
            }}
          />
          <img src={RANKS[rank].icon} />
        </div>
      </div>
    )
  );
}

function Leaderboards() {
  const params = useParams();
  const navigate = useNavigate();
  const type = params.type ?? "ones";
  const season = params.season ?? "s2";
  const data = useData(season, type);

  const onTypeChanged = useCallback((newType) => {
    navigate(`/leaderboards/${season}/${newType}`);
  });

  const onSeasonChanged = useCallback((newSeason) => {
    navigate(`/leaderboards/${newSeason}/${type}`);
  });

  if (data == null) {
    return (
      <LeaderboardWrapper
        type={type}
        season={season}
        onTypeChanged={onTypeChanged}
        onSeasonChanged={onSeasonChanged}
      >
        <div style={{ textAlign: "center" }}>LOADING...</div>
      </LeaderboardWrapper>
    );
  }

  const rows = data.slice(0, 50).map((row, idx) => {
    return (
      <Row
        key={row.id + row.class}
        number={<>{idx + 1}.</>}
        icon={
          <img
            style={{ borderRadius: 8 }}
            src={row.avatar}
            width="32"
            height="32"
          />
        }
        highlight={idx % 2 === 1}
        name={
          <Link to={"/player/" + row.id}>
            {idx == 0 ? (
              <>
                {row.name}
                <i className="fas fa-crown" style={{ marginLeft: 4 }} />
              </>
            ) : (
              row.name
            )}
          </Link>
        }
        classIcon={
          <img
            style={{ borderRadius: 8 }}
            src={getIconForClass(row.class)}
            width="32"
            height="32"
          />
        }
        rank={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 8,
            }}
          >
            <RankDisplay rank={row.rank} />
            <div style={{ width: 64 }}>({row.mmr})</div>
          </div>
        }
      />
    );
  });

  const content = rows.length > 0 ? rows : <div>NO ENTRIES.</div>;

  return (
    <LeaderboardWrapper
      type={type}
      season={season}
      onTypeChanged={onTypeChanged}
      onSeasonChanged={onSeasonChanged}
    >
      <div>
        <Row
          number="#"
          icon={<div style={{ display: "inline-block", width: 32 }} />}
          name="NAME"
          classIcon={"TITAN"}
          rank="RANK / MMR"
        />
        <div style={{ height: 1, background: "white", margin: 8 }} />
        {content}
      </div>
    </LeaderboardWrapper>
  );
}

export default Leaderboards;
