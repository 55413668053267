%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: ShadowBlow
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: d44b46a27bd0a8f4e83641e36dffdc4b, type: 3}
  beforeChannelSoundDelay: 200
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 2
  castDelay: 420
  animationSpeed: 1
  animationDuration: 0.8
  skipCooldownOnChannel: 0
  spellName: Shadow Blow
  description: 'Deal damage to the target based on the number of combo points you
    have.


    <b>1 Combo Point</b>: 8 damage

    <b>2 Combo Points</b>: 12 damage

    <b>3
    Combo Points</b>: 18 damage

    <b>4 Combo Points</b>: 27 damage

    <b>5 Combo
    Points</b>: 40 damage'
  icon: {fileID: 2800000, guid: b08893aeec1f1cb42b234604696ddf0f, type: 3}
  usableBy: 010000000a000000
  cooldown: 0
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 12d2ef2f459217549b413b4b15bf4b0e, type: 2}
