import { Link, useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "./PageLayout";
import { useEffect, useState } from "react";
import callFirebaseFunction from "./firebase/callFirebaseFunction";
import { CLASS_ICON_MAP, RankDisplay, SEASONS, TYPES } from "./Leaderboards";
import { inputStyle } from "./CreatorPortal";
import { Button } from "./LandingPage";

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  let timeString = "";
  if (minutes > 0) {
    timeString += `${minutes} min`;
  }
  if (remainingSeconds > 0) {
    if (minutes > 0) {
      timeString += ", ";
    }
    timeString += `${remainingSeconds} sec`;
  }

  return timeString || "0 sec"; // handle case where seconds = 0
}

function PlayerBox({ playerInfo, won }) {
  return (
    <Link to={"/player/" + playerInfo.steamId}>
      <div
        style={{
          position: "relative",
          padding: 4,
          borderRadius: 8,
          background: won ? "rgb(23, 62, 20)" : "#881c1c",
          display: "flex",
          gap: 4,
          zIndex: 99,
          alignItems: "center",
          color: "white",
        }}
      >
        <img
          src={CLASS_ICON_MAP[playerInfo.titan.toLowerCase()]}
          style={{ borderRadius: 4 }}
        />
        <img src={playerInfo.icon} style={{ borderRadius: 8 }} />
        <div>
          {playerInfo.name} ({playerInfo.mmr})
        </div>
      </div>
    </Link>
  );
}

function MatchRow({ steamId, matchInfo, highlight }) {
  let team1 = matchInfo.team1;
  let team2 = matchInfo.team2;

  let winner = matchInfo.winner;
  if (team1.findIndex((x) => x.steamId === steamId) === -1) {
    [team1, team2] = [team2, team1];
    winner = matchInfo.winner === 1 ? 2 : 1;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: highlight ? "rgba(255, 255, 255, 0.05)" : "none",
          padding: 8,
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
        }}
      >
        {new Date(matchInfo.startTime * 1000).toLocaleString()}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 4,
          background: highlight ? "rgba(255, 255, 255, 0.05)" : "none",
          padding: 8,
        }}
      >
        {team1.map((x, idx) => (
          <PlayerBox key={idx} playerInfo={x} won={winner === 1} />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 4,
          background: highlight ? "rgba(255, 255, 255, 0.05)" : "none",
          padding: 8,
        }}
      >
        {team2.map((x, idx) => (
          <PlayerBox key={idx} playerInfo={x} won={winner === 2} />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          background: highlight ? "rgba(255, 255, 255, 0.05)" : "none",
          padding: 8,
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
        }}
      >
        {formatTime(matchInfo.endTime - matchInfo.startTime)}
      </div>
    </>
  );
}

function MatchHistoryTable({ steamId, data }) {
  if (data == null) {
    return "Loading...";
  }

  if (data.length === 0) {
    return "No match history available.";
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "200px 1fr 1fr 130px",
        fontFamily: '"Montserrat", sans-serif',
      }}
    >
      <div style={{ fontSize: 20, fontWeight: "bold", padding: 4 }}>DATE</div>
      <div style={{ fontSize: 20, fontWeight: "bold", padding: 4 }}>TEAM 1</div>
      <div style={{ fontSize: 20, fontWeight: "bold", padding: 4 }}>TEAM 2</div>
      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          padding: 4,
          textAlign: "right",
        }}
      >
        DURATION
      </div>

      {data.map((matchInfo, idx) => (
        <MatchRow
          key={idx}
          steamId={steamId}
          matchInfo={matchInfo}
          highlight={idx % 2 === 0}
        />
      ))}
    </div>
  );
}

function MatchHistory({ steamId }) {
  const [data, setData] = useState(null);
  const [afterIds, setAfterIds] = useState([]);

  useEffect(async () => {
    setData(null);
    const history = await callFirebaseFunction("getMatchHistory", {
      steamId,
      after: afterIds.length > 0 ? afterIds[afterIds.length - 1] : null,
    });
    setData(history);
  }, [steamId, afterIds]);

  return (
    <>
      <MatchHistoryTable steamId={steamId} data={data} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {data != null && afterIds.length > 0 ? (
          <Button
            style={{ borderRadius: 12 }}
            icon={<i className="fas fa-chevron-left" />}
            onClick={() => {
              setAfterIds((old) => old.slice(0, old.length - 1));
            }}
          >
            PREVIOUS PAGE
          </Button>
        ) : (
          <div />
        )}

        {data != null && data.length >= 50 ? (
          <Button
            style={{ borderRadius: 12 }}
            afterIcon={<i className="fas fa-chevron-right" />}
            onClick={() => {
              setAfterIds((old) => [...old, data[data.length - 1].id]);
            }}
          >
            NEXT PAGE
          </Button>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}

function SearchBox() {
  const navigate = useNavigate();
  const [searchSteamId, setSearchSteamId] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();

        navigate("/player/" + searchSteamId);
      }}
    >
      <div
        style={{
          ...inputStyle,
          display: "flex",
          gap: 8,
          alignItems: "center",
        }}
      >
        <i className="fas fa-search" />
        <input
          type="search"
          style={{
            background: "none",
            border: "none",
            outline: "none",
            color: "white",
          }}
          placeholder="Enter a steam ID..."
          value={searchSteamId}
          onChange={(e) => setSearchSteamId(e.target.value)}
        />
      </div>
    </form>
  );
}

function PlayerInfo({ steamId }) {
  const [data, setData] = useState(false);

  useEffect(async () => {
    setData(null);
    const result = await callFirebaseFunction("getPlayerInfo", { steamId });
    setData(result);
  }, [steamId]);

  if (data == null) {
    return "Loading...";
  }

  return (
    <div
      style={{ display: "flex", gap: 8, alignItems: "center", fontSize: 22 }}
    >
      <img
        style={{ width: 32, height: 32, borderRadius: 16 }}
        src={data.icon}
      />
      <div>{data.name}</div>
      <div>•</div>
      <div style={{ fontSize: 14 }}>{steamId}</div>
      <div>•</div>
      <div style={{ fontSize: 14 }}>PLAYED {data.numMatchesPlayed} MATCHES</div>
    </div>
  );
}

const CLASSES = {
  mage: "Cryomancer Azora",
  rogue: "Assassin Volen",
  priest: "Cleric Kanna",
  warlock: "Plaguebringer Malrah",
  warrior: "Fighter Tarcza",
  druid: "Naturalist Airion",
  hunter: "Hunter Orin",
  paladin: "Lightbringer Anthriel",
  dark_priest: "Soulstealer Kanna",
  crystal_mage: "Crystalmancer Azora",
  bruiser_rogue: "Bruiser Volen",
  blademaster_warrior: "Blademaster Tarcza",
  eradicator_warlock: "Eradicator Malrah",
  smiter_paladin: "Smiter Anthriel",
  corruption_airion: "Stormcaller Airion",
  marksman_orin: "Marksman Orin",
};

function TitanInfo({ steamId }) {
  const [data, setData] = useState(false);
  const [type, setType] = useState("ones");
  const [season, setSeason] = useState("s2");
  const [titan, setTitan] = useState("mage");

  useEffect(async () => {
    setData(null);
    const result = await callFirebaseFunction("getPlayerTitanRankInfo", {
      steamId,
      type,
      season,
      titan,
    });
    setData(result);
  }, [steamId, type, season, titan]);

  if (data == null) {
    return "Loading...";
  }

  const seasonOptions = Object.keys(SEASONS).map((key) => {
    return (
      <option key={key} value={key}>
        {SEASONS[key].toUpperCase()}
      </option>
    );
  });
  const titanOptions = Object.keys(CLASSES).map((key) => {
    return (
      <option key={key} value={key}>
        {CLASSES[key].toUpperCase()}
      </option>
    );
  });
  const typeOptions = Object.keys(TYPES).map((key) => {
    return (
      <option key={key} value={key}>
        {TYPES[key].toUpperCase()}
      </option>
    );
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
      <div style={{ display: "flex", gap: 8 }}>
        <select value={season} onChange={(e) => setSeason(e.target.value)}>
          {seasonOptions}
        </select>
        <select value={titan} onChange={(e) => setTitan(e.target.value)}>
          {titanOptions}
        </select>
        <select value={type} onChange={(e) => setType(e.target.value)}>
          {typeOptions}
        </select>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {data.mmr != null && (
          <div>
            <strong>MMR:</strong> {data.mmr}
          </div>
        )}
        {data.rank != null && (
          <div>
            <strong>RANK:</strong>{" "}
            <div style={{ display: "inline-block" }}>
              <RankDisplay rank={data.rank} />
            </div>
          </div>
        )}
        {data.ranking != null && (
          <div>
            <strong>RANKING:</strong> #{data.ranking} ON LEADERBOARDS
          </div>
        )}
      </div>
    </div>
  );
}

export default function PlayerPage() {
  const params = useParams();
  const steamId = params.steamId;

  return (
    <PageLayout>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PlayerInfo steamId={steamId} />
          <SearchBox />
        </div>
        <div
          style={{
            padding: 20,
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: 16,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <h2 style={{ margin: 0 }}>TITAN INFO</h2>
          <TitanInfo key={steamId} steamId={steamId} />
        </div>
        <div
          style={{
            padding: 20,
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: 16,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <div>
            <h2 style={{ margin: 0 }}>MATCH HISTORY</h2>
            <p
              style={{
                margin: 0,
                marginTop: 4,
                fontFamily: '"Montserrat", sans-serif',
                fontSize: 14,
                color: "#ddd",
              }}
            >
              Match history is delayed by 3 hours to prevent
              sniping/dodging/etc.
            </p>
          </div>
          <MatchHistory key={steamId} steamId={steamId} />
        </div>
      </div>
    </PageLayout>
  );
}
