import { PageLayout } from "./PageLayout";

export default function LinkDiscord() {
  return (
    <PageLayout label="LINK DISCORD">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          textAlign: "center",
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        <div>
          You can Link your Discord account if you'd like to get special Roles
          granted on the official{" "}
          <a href="https://discord.gg/8xRbPjgg7p">Ring of Titans Discord</a>{" "}
          based on your in-game accomplishments. Note that you must have your
          Steam linked and verified on Discord. You may need to unlink and
          re-link your Steam to Discord in order to ensure it has been verified.
        </div>
        <div>
          Also note that this is a one-time sync, so if you have new
          accomplishments in game, you'll have to come back and re-link to get
          those to update on Discord.
        </div>
        <div>
          <a href="https://discord.com/oauth2/authorize?client_id=939722825005170730&response_type=code&redirect_uri=https%3A%2F%2Flinkdiscord-vg6rnx7kwa-uc.a.run.app%2F&scope=connections+identify">
            Click here
          </a>{" "}
          to link your account.
        </div>
      </div>
    </PageLayout>
  );
}
