%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: PoisonBlade
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 135
  castDelay: 320
  animationSpeed: 1
  animationDuration: 0.7
  skipCooldownOnChannel: 0
  spellName: Poison Blade
  description: '<b>Stun</b> the target for a duration based on the number of combo
    points you have.


    <b>1 Combo Point</b>: 0 seconds

    <b>2 Combo Points</b>:
    1 second

    <b>3 Combo Points</b>: 2 seconds

    <b>4 Combo Points</b>: 3
    seconds

    <b>5 Combo Points</b>: 4 seconds'
  icon: {fileID: 2800000, guid: 6e2799ea8f38db3479293189cbfbf2d9, type: 3}
  usableBy: 01000000
  cooldown: 20
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 12d2ef2f459217549b413b4b15bf4b0e, type: 2}
