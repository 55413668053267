%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: CrystalBreath
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: b1e3c0f02d3d52e4282044628720bede,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: c6a46772a396efa4c95545ff89c47323, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 15
  castDelay: 240
  animationSpeed: 2.7
  animationDuration: 0.7
  skipCooldownOnChannel: 0
  spellName: Crystal Breath
  description: Deal <b>18</b> damage and <b>Incapacitate</b> all targets in a cone
    in front of you for <b>5</b> seconds. Any damage dealt will break the effect.
  icon: {fileID: 2800000, guid: 45d1cea6dc009cf40893a6b593303eba, type: 3}
  usableBy: 
  cooldown: 28
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100051
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 2ccb1f54149fb664e821eb6693fc67e2, type: 3}
