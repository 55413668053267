%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: HolyFortitude
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 73c526bbb006fde49a6978e33b9c6786,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 14
  castDelay: 0
  animationSpeed: 2.5
  animationDuration: 0.5
  skipCooldownOnChannel: 0
  spellName: Holy Fortitude
  description: Reduce all incoming damage on the target by <b>60%</b>. In addition,
    increase all incoming healing to the target by <b>20%</b>. Can be cast while
    <b>Stunned</b> or <b>Incapacitated</b>. Lasts <b>10</b> seconds.
  icon: {fileID: 2800000, guid: d8eaed9766a06554b97cf29203f38c1f, type: 3}
  usableBy: 02000000
  cooldown: 60
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 027489edcf0967b4c9cd9e38e44be6cf, type: 2}
