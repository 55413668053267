import { Header } from "./Header";
import { Footer } from "./Footer";

export function PageLayout(props) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 20,
          background: "#040404",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: props.width ? props.width : 960,
            maxWidth: "100%",
            margin: "20px auto",
            gap: 20,
          }}
        >
          <div style={{ width: 960, maxWidth: "100%" }}>
            <Header label={props.label} />
          </div>
          <div
            style={{
              padding: 20,
              background: "rgba(0, 0, 0, 0.9)",
              borderRadius: 16,
              width: "100%",
              zIndex: 0,
              ...props.style,
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
