import LandingPage from "./LandingPage";
import Leaderboards from "./Leaderboards";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PatchNotes from "./PatchNotes";
import Titans from "./Titans";
import CreatorsProgram from "./CreatorsProgram";
import CreatorPortal from "./CreatorPortal";
import PlayerPage from "./PlayerPage";
import SuccessLink from "./SuccessLink";
import LinkDiscord from "./LinkDiscord";
import FailedLink from "./FailedLink";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/leaderboards/:season/:type" element={<Leaderboards />} />
        <Route path="/leaderboards" element={<Leaderboards />} />
        <Route path="/patchnotes" element={<PatchNotes />} />
        <Route path="/titans" element={<Titans />} />
        <Route path="/titan/:titan" element={<Titans />} />
        <Route path="/creatorprogram" element={<CreatorsProgram />} />
        <Route path="/creator" element={<CreatorPortal />} />
        <Route path="/player/:steamId" element={<PlayerPage />} />
        <Route path="/link/discord/" element={<LinkDiscord />} />
        <Route path="/link/discord/success" element={<SuccessLink />} />
        <Route path="/link/discord/error" element={<FailedLink />} />
      </Routes>
    </Router>
  );
}

export default App;
