%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: Mount
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 8300000, guid: 61932a98031a8014685b4598a7858066, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 4
  animationVariation: 0
  castDelay: 0
  animationSpeed: 0.5
  animationDuration: 1.5
  skipCooldownOnChannel: 0
  spellName: Mount
  description: Mount up, increasing movement speed by 100%. Any spell cast will dismount
    you.
  icon: {fileID: 2800000, guid: 10574bc17976a0e4fb39ef3f781232da, type: 3}
  usableBy: 000000000100000002000000030000000400000005000000060000000700000008000000090000000a0000000b0000000c0000000d0000000e0000000f00000011000000
  cooldown: 0
  castTime: 1.5
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 0
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
