%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerWarlock
  m_EditorClassIdentifier: 
  unitModel: {fileID: 5925594905447184141, guid: a195385654101404d995fcab48ff43e4,
    type: 3}
  heroClass: 4
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 156
  unitName: Malrah
  unitSpec: Plaguebringer
  icon: {fileID: 2800000, guid: 2f5f12c1e28063045b07f1b6a009ec08, type: 3}
  backgroundColor: {r: 0.4606976, g: 0.1965112, b: 0.6037736, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: a231144642eabe64691435d0172b660b, type: 3}
  type: MAGE
  description: Malrah, the enigmatic Plaguebringer, casts her shadowy presence across
    the Ring of Titans, wielding dark magic with deadly precision. Specializing in
    mass devastation, she unleashes torrents of shadow energy to inflict damage upon
    multiple adversaries simultaneously, leaving chaos in her wake. Her mastery over
    the arcane arts extends to the macabre ability to siphon the life force from
    her foes, granting her vitality as she drains theirs. With each eerie whisper
    and flicker of her cloak, Malrah commands fear and respect, a formidable force
    in the arena of combat.
  passiveIcon: {fileID: 21300000, guid: dce10b7b34f9f514288288c28ca7ee3a, type: 3}
  passiveName: Soul Essence
  passiveDescription: Every time you use a targeted damaging ability, collect a <b>Soul
    Essence</b>, reducing the physical damage you take by <b>1%</b>. Stacks up to
    <b>10</b> times.
