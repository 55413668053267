%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerSmiterPaladin
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2181780924318426476, guid: 60874159367d7634699066f188942bb7,
    type: 3}
  heroClass: 13
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 200
  unitName: Anthriel
  unitSpec: Smiter
  icon: {fileID: 2800000, guid: 2af88f958103e1c4bbedf6f01bf60ee3, type: 3}
  backgroundColor: {r: 0.18926665, g: 0.46226418, b: 0.22623509, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: BRUISER
  description: Anthriel is a Smiter who wields a colossal hammer and holy magic to
    dominate her adversaries. Her combat prowess is unmatched, delivering devastating
    blows with swift precision. Not only can she deal tremendous damage swiftly,
    but she also serves as a beacon of hope for her allies, adeptly wielding her
    holy magic to heal wounds and provide support when needed. With her unwavering
    resolve and versatile abilities, Anthriel stands as a stalwart defender of justice
    in the arena, ready to vanquish any foe that dares to challenge her.
  passiveIcon: {fileID: 21300000, guid: 24fe1e4458bec764193ad1e355df4991, type: 3}
  passiveName: Divine Empowerment
  passiveDescription: Upon dealing damage, gain a stack of <b>Divine Empowerment</b>.
    Consume it to heal <b>2</b> extra per stack, or gain <b>6%</b> movespeed per
    stack when casting <b>Righteous Privelege</b> on yourself.
