import { PageLayout } from "./PageLayout";
import Countdown from "react-countdown";

const TRAILER_WIDTH = 960;
const TRAILER_HEIGHT = TRAILER_WIDTH / (16 / 9);

export const Button = ({
  icon,
  afterIcon,
  spinIcon,
  children,
  link,
  onClick,
  target,
  style,
}) => {
  const targetOpt = {};
  if (target) {
    targetOpt.target = target;
  }

  const extraIconStyles = spinIcon
    ? {
        animation: "spin 800ms linear infinite",
      }
    : {};

  return (
    <a
      href={link}
      onClick={onClick}
      {...targetOpt}
      style={{ textDecoration: "none" }}
    >
      <div
        className="btn"
        style={{
          display: "flex",
          gap: 8,
          padding: 16,
          cursor: "pointer",
          textShadow: "0 0 5px black",
          color: "white",
          lineHeight: 1,
          ...style,
        }}
      >
        {icon && (
          <div style={{ display: "inline-block", ...extraIconStyles }}>
            {icon}
          </div>
        )}
        {children}
        {afterIcon && (
          <div style={{ display: "inline-block", ...extraIconStyles }}>
            {afterIcon}
          </div>
        )}
      </div>
    </a>
  );
};

const NumField = ({ amt }) => {
  if (amt < 10) {
    amt = "0" + amt;
  }

  return <>{amt}</>;
};

const ColonField = () => {
  return <>:</>;
};

const countdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  props,
}) => {
  if (completed) {
    return props.children;
  } else {
    return (
      <div style={{ textAlign: "center" }}>
        <NumField amt={days} />
        <ColonField />
        <NumField amt={hours} />
        <ColonField />
        <NumField amt={minutes} />
        <ColonField />
        <NumField amt={seconds} />
      </div>
    );
  }
};

function LandingPage() {
  return (
    <PageLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 20,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Roboto Mono", monospace',
          }}
        >
          <div style={{ fontSize: 40, lineHeight: 1 }}>1.0 RELEASES IN:</div>
          <div
            style={{
              fontSize: "5vw",
              lineHeight: 1,
              fontWeight: 800,
            }}
          >
            <Countdown date={1729533600000} renderer={countdownRenderer}>
              1.0 IS RELEASED!
            </Countdown>
          </div>
        </div>
        <iframe
          style={{
            overflow: "hidden",
            borderRadius: 12,
            width: "100%",
            height: TRAILER_HEIGHT,
            maxWidth: TRAILER_WIDTH,
          }}
          src="https://www.youtube.com/embed/rHERYwv1tao"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </PageLayout>
  );
}

export default LandingPage;
