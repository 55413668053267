%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: CrystalBlast
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: b1e3c0f02d3d52e4282044628720bede,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 8300000, guid: c6a46772a396efa4c95545ff89c47323, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 22
  castDelay: 0
  animationSpeed: 2
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Crystal Blast
  description: Deal <b>10</b> damage and <b>Stun</b> all enemies within <b>6</b>
    yards of the targeted area.
  icon: {fileID: 2800000, guid: d7c2298a565d7cc41b2a8fe0ffb0d680, type: 3}
  usableBy: 09000000
  cooldown: 30
  castTime: 1
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 6
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
  spellKey: 100014
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 057dde6365dd2204c9d246081cc615c3, type: 3}
