%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: MaceSwing
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 8300000, guid: 004255efa44392a4ab6ff82bc342a7ef, type: 3}
  afterChannelSoundDelay: 215
  animation: 0
  animationVariation: 4
  castDelay: 350
  animationSpeed: 1
  animationDuration: 0.5
  skipCooldownOnChannel: 0
  spellName: Mace Swing
  description: Deal <b>5</b> damage to the target.
  icon: {fileID: 2800000, guid: 60cfd603bb0c29c44bd0c258fcddaa23, type: 3}
  usableBy: 07000000
  cooldown: 0
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 12d2ef2f459217549b413b4b15bf4b0e, type: 2}
