%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerRogue
  m_EditorClassIdentifier: 
  unitModel: {fileID: 1737313431337791715, guid: 523a1bb40963957419f0bf724105e1cd,
    type: 3}
  heroClass: 1
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 172
  unitName: Volen
  unitSpec: Assassin
  icon: {fileID: 2800000, guid: 2bac70bcc3eeba8468ef80431e0bd35a, type: 3}
  backgroundColor: {r: 0.9921569, g: 0.7727113, b: 0.1411765, a: 1}
  textColor: {r: 0.4, g: 0.4, b: 0.4, a: 1}
  typeIcon: {fileID: 21300000, guid: 95cbed828bf835049b3345c4a977845e, type: 3}
  type: ASSASSIN
  description: Volen, a feared assassin renowned within the Ring of Titans, wields
    two deadly daggers with unparalleled finesse. His mastery extends beyond mere
    physical prowess; he exists in the space between reality and the shadow realm,
    granting him the ability to seamlessly traverse between the two realms. This
    unique talent enables him to stalk his prey with precision, biding his time until
    the opportune moment to strike arises. Employing a combination of swift strikes
    and shadowy maneuvers, Volen can control the battlefield, slowing his adversaries,
    stunning them with his swift agility, and ultimately incapacitating them with
    lethal precision. In the arena, he is a silent but deadly force to be reckoned
    with, his presence alone striking fear into the hearts of his foes.
  passiveIcon: {fileID: 21300000, guid: 21070711530542441a9e5302c385f2f3, type: 3}
  passiveName: Dark Blood
  passiveDescription: After leaving <b>Shadow Crawl</b>, deal <b>10%</b> additional
    phyiscal damage for <b>4</b> seconds.
