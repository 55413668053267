%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: Slice
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 8300000, guid: ee793a397c7c8b841a9cc6a74d9cff7c, type: 3}
  castSoundVolume: 0.4
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 8300000, guid: ee793a397c7c8b841a9cc6a74d9cff7c, type: 3}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 33
  castDelay: 0
  animationSpeed: 1.5
  animationDuration: 1.5
  skipCooldownOnChannel: 0
  spellName: Slice
  description: Slice forward <b>20</b> yards. Any targets in your path take <b>10</b>
    damage and bleed for <b>4</b> damage every <b>2</b> seconds for <b>20</b> seconds.
  icon: {fileID: 2800000, guid: 4844f73b629221244a95cef134b0be89, type: 3}
  usableBy: 0b000000
  cooldown: 20
  castTime: 0.8
  isCastInterruptible: 0
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 80cddad32b4f07e4bbf640afdccb32f4, type: 2}
  spellKey: 100021
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: b9ad019ab621fd2479604084efc3117d, type: 3}
