%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: PureCrystal4
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: b1e3c0f02d3d52e4282044628720bede,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: c6a46772a396efa4c95545ff89c47323, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 301
  castDelay: 200
  animationSpeed: 1.5
  animationDuration: 0.4
  skipCooldownOnChannel: 0
  spellName: Pure Crystal
  description: '<b>First Cast</b>: Throw a pure crystal at the target, dealing <b>8</b>
    damage and teleporting you to it on impact. Gain one <b>Pure Crystal</b>.


    <b>Second
    Cast</b>: Deal <b>15</b> damage to all enemies in a 10 yard radius. Gain one
    <b>Pure Crystal</b>.


    <b>Third Cast</b>: Deal <b>18</b> damage and throw
    a crystal 40 yards through the target, teleporting to it upon impact. Gain one
    <b>Pure Crystal</b>.


    <b>Fourth Cast</b>: Instantly unleash all <b>Pure
    Crystals</b> at <b>75%</b> effectiveness.'
  icon: {fileID: 2800000, guid: 4122ebf4377dff94aa873fccd45d739f, type: 3}
  usableBy: 09000000
  cooldown: 3
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 1
  isInternal: 1
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100041
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: ac44b3b333d57144589f6ea7791abe26, type: 3}
