%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: ShortDraw
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: e1469613e3c96a34a99dc366ba596c51,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 8300000, guid: b49169f973e2c674caf89289d6b096a1, type: 3}
  castSoundVolume: 1
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 29
  castDelay: 0
  animationSpeed: 0.21
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Short Draw
  description: Deal <b>14</b> damage to the target.
  icon: {fileID: 2800000, guid: 183d4ae782a28e14ab580b67ad3c9db8, type: 3}
  usableBy: 0f000000
  cooldown: 0
  castTime: 0.9
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100070
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 15fd5623231743b4cb74624c2ea57ed2, type: 3}
