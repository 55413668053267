%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: ChainsOfIce
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 31f0c923b7b660e47938376fb189e949,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 21
  castDelay: 380
  animationSpeed: 2.8
  animationDuration: 0.7
  skipCooldownOnChannel: 0
  spellName: Chains of Ice
  description: Deal <b>1</b> damage and prevent all targets in a <b>10</b> yard radius
    at the mouse position from moving for <b>8</b> seconds. Targets are considered
    <b>Frozen</b> for this duration. Can be cast while <b>Stunned</b> or <b>Incapacitated</b>.
  icon: {fileID: 2800000, guid: a4e342ff663a43545bbb98d416cafc0a, type: 3}
  usableBy: 00000000
  cooldown: 20
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 10
  isHidden: 0
  isInternal: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
