import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAWy_AdDDm7ueDHLuvzwG3YQL2Asfh9R4w",
  authDomain: "unity-d90a5.firebaseapp.com",
  databaseURL: "https://unity-d90a5.firebaseio.com",
  projectId: "unity-d90a5",
  storageBucket: "unity-d90a5.appspot.com",
  messagingSenderId: "465051373740",
  appId: "1:465051373740:web:9809c8f19431c76e918f09",
};

export const app = initializeApp(firebaseConfig);
