%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerMarksmanHunter
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2910940308793556349, guid: 685ccb12f928e04408126300cdbb68df,
    type: 3}
  heroClass: 15
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 184
  unitName: Orin
  unitSpec: Marksman
  icon: {fileID: 2800000, guid: 110e14c2d23805f4cbee7472b57b7cf4, type: 3}
  backgroundColor: {r: 0.18490559, g: 0.18490559, b: 0.18490559, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 95cbed828bf835049b3345c4a977845e, type: 3}
  type: RANGED
  description: Orin is a Marksman who epitomizes precision and devastation on the
    battlefield. A master of dealing immense damage from a distance, Orin's prowess
    is unmatched when allowed to stand undisturbed, his aim unwavering and lethal.
    Yet, for all his offensive might, Orin is vulnerable when the spotlight turns
    his way, lacking the resilience to withstand direct assault. Thus, in the dance
    of combat, Orin must balance his unmatched offensive capabilities with strategic
    positioning and swift evasiveness to survive and reign supreme in the arena.
  passiveIcon: {fileID: 21300000, guid: c0da235e560fec9448a025b35e9e80bf, type: 3}
  passiveName: Headshot
  passiveDescription: Every <b>5th</b> arrow shot deals <b>10%</b> additional damage.
