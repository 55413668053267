%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: Thorns
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 727422648304881801, guid: a9f11eefd58c0244ba94098589ebf7db,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 044eae113b60d7e46b52f96eb863fa08, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 26
  castDelay: 450
  animationSpeed: 3.5
  animationDuration: 0.7
  skipCooldownOnChannel: 0
  spellName: Thorns
  description: Thorns erupt from the ground, following the target for <b>10</b> seconds,
    dealing <b>3</b> damage all enemies who are hit by them every <b>0.25</b> seconds.
  icon: {fileID: 2800000, guid: 3b7be4ee8548c6b418338d414b669e4e, type: 3}
  usableBy: 0e000000
  cooldown: 45
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100066
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: c8b6945e4d88383428d935c19a177057, type: 3}
