import { httpsCallable } from "@firebase/functions";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { app } from "./app.js";

const functions = getFunctions(app);

if (process.env.NODE_ENV !== "production") {
  connectFunctionsEmulator(functions, window.location.hostname, 5001);
}

export default async function callFirebaseFunction(endpoint, params) {
  const resp = await httpsCallable(functions, endpoint)(params);
  return resp.data;
}
