%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: FrostCrystal3
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 31f0c923b7b660e47938376fb189e949,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 13
  castDelay: 180
  animationSpeed: 1.8
  animationDuration: 0.4
  skipCooldownOnChannel: 0
  spellName: Frost Crystal
  description: '<b>First Cast</b>: Deal <b>10</b> magic damage and slow the target
    by <b>50%</b> for <b>8</b> seconds. Must be in melee range. Gain one <b>Frost
    Crystal</b>.


    <b>Second cast</b>: Deal <b>22</b> magic damage and slow the
    target by <b>50%</b> for <b>8</b> seconds. Snares the target in place for <b>8</b>
    seconds upon impact. Gain one <b>Frost Crystal</b>.


    <b>Third cast</b>:
    Project a Frost Orb which moves slowly towards your target and damages and slows
    all targets in its radius. Gain one <b>Frost Crystal</b>.


    <b>Fourth cast</b>:
    Unleash all <b>Frost Crystals</b> at <b>75%</b> effectiveness with a <b>1</b>
    second cast time.'
  icon: {fileID: 2800000, guid: 11f18a9c024828a4f8771bddef8a06b6, type: 3}
  usableBy: 09000000
  cooldown: 3
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 1
  isInternal: 1
  isRegional: 0
  preventManualCasts: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100043
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 469d5499017831445aff9ef9eaba238a, type: 3}
