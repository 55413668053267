%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: FireCrystal4
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.05
  castAuraPrefab: {fileID: 7489370104637420957, guid: 188ccd91914323f48a7ff9686cb3242a,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 8300000, guid: 3f776ba755ba71341bb3f527c8369c60, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 0
  castDelay: 0
  animationSpeed: 0.395
  animationDuration: 1.5
  skipCooldownOnChannel: 0
  spellName: Fire Crystal
  description: '<b>First Cast</b>: Deal <b>15</b> magic damage. Must be in melee
    range. Gain one <b>Fire Crystal</b>.


    <b>Second cast</b>: Deal <b>31</b>
    magic damage. Gain one <b>Fire Crystal</b>.


    <b>Third cast</b>: Deal <b>16</b>
    magic damage to all enemies within <b>8</b> yards of the target. Gain one <b>Fire
    Crystal</b>.


    <b>Fourth cast</b>: Unleash all <b>Fire Crystals</b> at 75%
    effectiveness with a <b>1.5</b> second cast time.'
  icon: {fileID: 2800000, guid: ff6e288a238c87c49870f9735a1f78f9, type: 3}
  usableBy: 09000000
  cooldown: 0
  castTime: 1.5
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 1
  isInternal: 1
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100038
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: f4cf22be6fcd0ef4bbe058c8fc6c0704, type: 3}
