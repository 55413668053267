%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerCrystalMage
  m_EditorClassIdentifier: 
  unitModel: {fileID: 5226127952259784134, guid: 33a1d022504f42a449331a8e55e4cbbf,
    type: 3}
  heroClass: 9
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 128
  unitName: Azora
  unitSpec: Crystalmancer
  icon: {fileID: 2800000, guid: c6499b21fa0f0c84186119a11f06d9e0, type: 3}
  backgroundColor: {r: 0.2170167, g: 0.9056604, b: 0.5898942, a: 1}
  textColor: {r: 0.4, g: 0.4, b: 0.4, a: 1}
  typeIcon: {fileID: 21300000, guid: 697bc7f43d06ff744b1651cb141bfafe, type: 3}
  type: MAGE
  description: Azora is a daunting force, wielding the ethereal powers of crystal
    magic. Known as a Crystalmancer, she dazzles spectators with her mastery over
    all of the elements, crafting devastating spells that unleash torrents of destruction
    upon her opponents. Azora's prowess is unmatched; her ability to deal immense
    damage strikes fear into the hearts of her adversaries. However, her power comes
    with a perilous vulnerability, as she stands on the razor's edge of survival,
    susceptible to even the slightest misstep. In the arena, Azora dances with danger,
    harnessing the brilliance of her magic while teetering on the brink of demise.
    Her every move is a delicate balance between annihilation and victory, captivating
    audiences with the spectacle of her dazzling, yet precarious, performance.
  passiveIcon: {fileID: 21300000, guid: 34e5b75006acb3a4eaeece2479656353, type: 3}
  passiveName: Shattered Crystals
  passiveDescription: While below <b>30%</b> health, deal <b>30%</b> more magic damage.
