import { PageLayout } from "./PageLayout";

export default function FailedLink() {
  return (
    <PageLayout label="LINK DISCORD">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          textAlign: "center",
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        <div>
          There has been an error linking your Discord. Note that you must have
          your Steam account linked and verified on Discord. You may need to
          unlink and re-link your Steam to Discord in order to ensure it has
          been verified.
        </div>

        <div>
          <a href="https://discord.com/oauth2/authorize?client_id=939722825005170730&response_type=code&redirect_uri=https%3A%2F%2Flinkdiscord-vg6rnx7kwa-uc.a.run.app%2F&scope=connections+identify">
            Click here
          </a>{" "}
          to try again.
        </div>
      </div>
    </PageLayout>
  );
}
