%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: Pyrolance
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 188ccd91914323f48a7ff9686cb3242a,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 1
  playOnCast:
  - {fileID: 193574, guid: 549a96f1185245e438a33ecbf99a97d5, type: 3}
  castSound: {fileID: 8300000, guid: 3f776ba755ba71341bb3f527c8369c60, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 0
  castDelay: 0
  animationSpeed: 0.395
  animationDuration: 1.5
  skipCooldownOnChannel: 0
  spellName: Pyrolance
  description: Deal <b>28</b> damage to the target.
  icon: {fileID: 2800000, guid: c1d6653c52b46d840ae56e6b7842fbc6, type: 3}
  usableBy: 00000000
  cooldown: 0
  castTime: 1.5
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
