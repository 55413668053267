%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: Leap
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: 38939067706f25947b6a252ffec7753a, type: 3}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 36
  castDelay: 0
  animationSpeed: 1.1
  animationDuration: 2.2
  skipCooldownOnChannel: 0
  spellName: Leap
  description: Leap to the targeted location, dealing <b>12</b> damage and slowing
    all enemies within <b>6</b> yards by <b>30%</b> for <b>8</b> seconds upon impact.
  icon: {fileID: 2800000, guid: 73946e785106e4d429070471d7a5e4bb, type: 3}
  usableBy: 0b000000
  cooldown: 25
  castTime: 0.75
  isCastInterruptible: 0
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 6
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
  spellKey: 100053
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: 0248dd734f21dc543b47e75809a0f41d, type: 3}
