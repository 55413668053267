import { PageLayout } from "./PageLayout";

export default function SuccessLink() {
  return (
    <PageLayout label="LINK DISCORD">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          textAlign: "center",
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        Your Discord has been successfully linked! It may take some time to sync
        your roles on Discord.
      </div>
    </PageLayout>
  );
}
