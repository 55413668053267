%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: HookShot
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: e1469613e3c96a34a99dc366ba596c51,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  castSound: {fileID: 8300000, guid: b49169f973e2c674caf89289d6b096a1, type: 3}
  castSoundVolume: 1
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 29
  castDelay: 0
  animationSpeed: 1
  animationDuration: 0.4
  skipCooldownOnChannel: 0
  spellName: Hookshot
  description: 'Shoot a hook towards your cursor.


    If the hook collides with
    anything, you will pull yourself to it.


    If the hook collides with an enemy
    Titan, the enemy will be slowed by <b>50%</b> for <b>5</b> seconds.'
  icon: {fileID: 2800000, guid: 3e6e70441cdd1134eaa240028a1e3a0c, type: 3}
  usableBy: 0f000000
  cooldown: 12
  castTime: 0.2
  isCastInterruptible: 0
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 2
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 49714b7747fcdd245a2a48451ad05657, type: 2}
  spellKey: 100073
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: dcf6b0fca1590774f81dc1348161a684, type: 3}
